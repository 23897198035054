import Swiper, { Navigation, Keyboard, Thumbs } from "Parent/js/modules/swiper/swiper";

Swiper.use([Navigation, Keyboard, Thumbs]);

class Sliders {
	constructor() {
		this.home_painting_slider_el = document.querySelector(".home-painting-slider");
		this.testimonialshome_painting_slider = null;

        this.home_works_slider_el = document.querySelector(".home-works-slider");
		this.home_works_slider = null;

        this.inner_slider_el = document.querySelector(".inner-slider");
		this.inner_slider = null;

        this.inner_slider_thumbs_el = document.querySelector(".inner-slider-thumbs");
		this.inner_slider_thumbs = null;

		if (this.home_painting_slider_el) {
			this.runHomePaintingSlider();
		}

		if (this.home_works_slider_el) {
			this.runHomeWorksSlider();
		}

        if (this.inner_slider_thumbs_el) {
			this.runInnerSliderThumbs();
		}

		if (this.inner_slider_el) {
			this.runInnerSlider();
		}

	}

	runHomePaintingSlider() {
		this.home_painting_slider = new Swiper(this.home_painting_slider_el, {
			slidesPerView: 1,
			loop: true,
			grabCursor: true,
			navigation: {
				nextEl: ".home-painting-control-right"
			},
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            autoHeight: true
		});
	}

	runHomeWorksSlider() {
		this.home_works_slider = new Swiper(this.home_works_slider_el, {
			slidesPerView: 1,
			loop: true,
			navigation: {
				nextEl: ".home-works-control-right"
			},
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
			grabCursor: true,
            autoHeight: true
		});
	}

    runInnerSlider() {
		this.inner_slider = new Swiper(this.inner_slider_el, {
			slidesPerView: 1,
			grabCursor: true,
			loop: true,
            autoHeight: true,
			navigation: {
				nextEl: ".inner-control-right",
				prevEl: ".inner-control-left"
			},
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            thumbs: {
                swiper: this.inner_slider_thumbs
            }
		});
	}
    runInnerSliderThumbs() {
		this.inner_slider_thumbs = new Swiper(this.inner_slider_thumbs_el, {
            spaceBetween: 10,
            slidesPerView: 2,
            watchSlidesProgress: true,
			loop: true,
			grabCursor: true,
            breakpoints: {
				450: {
					slidesPerView: 3,
				},
				875: {
					slidesPerView: 4,
				},
			},
		});
	}
}

export default Sliders;
