class Globals {

    constructor() {
        const closeForm = document.getElementById('offCanvasClose');
        const offCanvas = document.getElementById('offCanvas');
        const menuTrigger = document.getElementById('mobileMenu');
        const menuBack = document.getElementById('offCanvasBack');

        window.addEventListener('resize', () => {
            if(window.innerWidth > 1023) {
                this.closeMenu();
            }
        }, true);

        //Attach event listener to offCanvasClose.
        closeForm.addEventListener("click", e => {
            this.closeMenu();
        });

        //Attach event listener to hamburger.
        menuTrigger.addEventListener("click", e => {
            this.openMenu();
            setTimeout(() => {
                this.triggerMenu();
            }, 200);
        });

        //Attach event listener to offCanvasBack.
        menuBack.addEventListener("click", e => {
            offCanvas.classList.remove('form');
            setTimeout(() => {
                offCanvas.classList.add('menu');
            }, 200);
        });

        //Attach event listeners to all career-trigger classes.
        let contactTriggers = document.querySelectorAll('.contact-trigger');

        Array.from(contactTriggers).forEach((contactTrigger) => {
            contactTrigger.addEventListener('click', e => {
                this.openMenu();
                this.triggerContact();
            });
        });
    }

    triggerContact() {
        offCanvas.classList.remove('menu');
        setTimeout(() => {
            offCanvas.classList.add('form');
        }, 200);
    }

    triggerMenu() {
        offCanvas.classList.add('menu');
    }

    openMenu() {
        const body = document.querySelector('body');

        offCanvas.classList.add('active');
        body.classList.add('active');
    }

    closeMenu() {
        const body = document.querySelector('body');

        offCanvas.classList.remove('active');
        body.classList.remove('active');

        setTimeout(() => {
            offCanvas.classList.remove('form');
        }, 200);
    }
}

export default Globals;
